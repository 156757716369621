import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import reducers from './reducers'
import {
    afterAuthenticated,
    getAuthToken,
    registerAuthHeaders,
    setAuthenticated,
    setAuthFeedback,
    setAuthUser,
    userInfo,
} from './actions/Auth'
import {
    displaySuccessfullyRegistered,
    getSuccessfullyRegistered,
    setErrorUI,
    setLoadingUI,
} from './actions/Session'
import {
    getContribuyenteStorage,
} from './actions/Contribuyente'

import registerMainInterceptor from './registerMainInterceptor'

registerMainInterceptor()

let middleWare = applyMiddleware(thunk)

// Config for reduxTools
if (process.env.NODE_ENV !== 'production') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    middleWare = composeEnhancers(middleWare)
}

export const store = createStore(reducers, middleWare)

// Auth Token for stateless authentication
store.dispatch(registerAuthHeaders())

try {
    if (getAuthToken()) {

        store.dispatch(setLoadingUI(true))
        store.dispatch(setAuthenticated(true))

        userInfo().then(userInfo => {
            if (!userInfo)
                store.dispatch(setErrorUI({ error: 'No hubo respuesta correcta' }))
            else {
                store.dispatch(setAuthUser(userInfo))
                store.dispatch(afterAuthenticated(userInfo, parseInt(getContribuyenteStorage(), 10))).then(() => {
                    store.dispatch(setLoadingUI())
                    if (getSuccessfullyRegistered())
                        store.dispatch(displaySuccessfullyRegistered())
                }).catch((error) =>
                    store.dispatch(setErrorUI(error))
                )
            }
        }).catch(error =>
            store.dispatch(setErrorUI(error))
        )
    } else if (getSuccessfullyRegistered()) {
        store.dispatch(displaySuccessfullyRegistered())
    }
} catch (error) {
    store.dispatch(setAuthFeedback('Debe tener las cookies del navegador habilitadas', true))
}
import ReactGA from 'react-ga'

export const setDocumentTitle = ({ title }) =>
    document.title = `${title} | ${process.env.REACT_APP_NAME} `

export const trackPage = ({ pathname, search }) => {
    const page = pathname + search
    ReactGA.set({
        page,
        appName: process.env.REACT_APP_NAME,
        appVersion: process.env.REACT_APP_VERSION,
    })
    ReactGA.pageview(page)
}
import axios from 'axios'
import { actions as toastrActions } from 'react-redux-toastr'

import {
    SET_ERROR_UI,
    SET_LOADING_UI,
    SET_NOTIFICATIONS,
    SET_PERMISOS
} from '../reducers/actions'

export const setErrorUI = (error = null) => ({
    type: SET_ERROR_UI,
    error,
})

export const setLoadingUI = (loading = false) => ({
    type: SET_LOADING_UI,
    loading,
})

export const setNotifications = (notifications = [], unread = 0) => ({
    type: SET_NOTIFICATIONS,
    notifications,
    unread
})

export const setPermisos = (permisos = null) => ({
    type: SET_PERMISOS,
    permisos
})

export const getPermisos = (data) => axios.post('/api/rol/permisos/empresa', data)

export const getSuccessfullyRegistered = () =>
    localStorage.getItem('successfullyRegistered')

export const successfullyRegistered = () =>
    localStorage.setItem('successfullyRegistered', true)

export const displaySuccessfullyRegistered = () => (dispatch) => {
    dispatch(toastrActions.add({
        type: 'success',
        title: 'Éxito',
        message: 'Registro de contribuyente completado exitosamente',
    }))
    localStorage.removeItem('successfullyRegistered')
}

import React, { Component } from 'react'

import { setDocumentTitle, trackPage } from './utils'

const withTitle = (ComponentWrapper, title) =>

    class WrappedComponent extends Component {

        componentDidMount() {
            setDocumentTitle({ title })
            trackPage(this.props.location)
        }

        componentDidUpdate({ location: currentLocation }) {
            const { location: nextLocation } = this.props
            const currentPage = currentLocation.pathname + currentLocation.search
            const nextPage = nextLocation.pathname + nextLocation.search

            if (currentPage !== nextPage) {
                setDocumentTitle({ title })
                trackPage(nextLocation)
            }
        }

        render() {
            return (
                <div className='animated fadeIn'>
                    <ComponentWrapper
                        {...this.props}
                    />
                </div>
            )
        }

    }

export default withTitle
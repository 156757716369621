/* Reducer actions */

/*  Session reducer actions */
export const SET_ERROR_UI = 'SET_ERROR_UI'
export const SET_LOADING_UI = 'SET_LOADING_UI'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_PERMISOS = 'SET_PERMISOS'

/* Auth reducer actions */
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_AUTH_FEEDBACK = 'SET_AUTH_FEEDBACK'
export const SET_AUTH_USER = 'SET_AUTH_USER'

/* Contribuyente reducer actions */
export const SET_CONTRIBUYENTE = 'SET_CONTRIBUYENTE'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'

import { store } from './store'
import MainApp from './MainApp'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_CODE)

ReactDOM.render(
    <Provider store={store}>
        <MainApp />
    </Provider>,
    document.getElementById('root'));
import axios from 'axios'

import {
    SET_AUTHENTICATED,
    SET_AUTH_FEEDBACK,
    SET_AUTH_USER,
} from '../reducers/actions'
import {
    setContribuyente,
} from './Contribuyente'
import {
    ojearNotificaciones
} from './Notifications'
import {
    getPermisos,
    setNotifications,
    setPermisos
} from './Session'

export const setAuthenticated = (authenticated = false, loggedOut = false) => ({
    type: SET_AUTHENTICATED,
    authenticated,
    loggedOut
})

export const setAuthFeedback = (message = null, error = false) => ({
    type: SET_AUTH_FEEDBACK,
    error,
    message,
})

export const setAuthUser = (user = null) => ({
    type: SET_AUTH_USER,
    user,
})

export const authenticate = (credentials) => axios.post('/api/login', credentials)

export const logout = () => axios.post('/api/logout')

export const forgotPassword = (data) => axios.post('/api/forgot', data)

export const recoverPassword = (data) => axios.post('/api/recover', data)

export const userInfo = () => axios.post('/api/me')

export const registerAuthHeaders = () => (dispatch) => {
    axios.interceptors.request.use((config) => {
        const authHeaderKey = 'Authorization'
        try {
            const token = getAuthToken()
            if (token)
                config.headers[authHeaderKey] = `Bearer ${token}`
            else
                delete config.headers[authHeaderKey];
        } catch (error) {
            // Storage cannot be read
        }
        return config;
    }, (error) => Promise.reject(error))

    axios.interceptors.response.use((response) => response, (error) => {
        if (error.response && error.response.status === 401) {
            dispatch(setAuthFeedback(error.message || 'No autorizado', true))
            dispatch(doLogout())
        }
        return Promise.reject(error)
    })
}

export const getAuthToken = () => localStorage.getItem('session')

export const setAuthToken = (token) => localStorage.setItem('session', token)

export const clearLocalStorage = () => localStorage.clear()

export const afterAuthenticated = (userInfo, contribuyenteId) => (dispatch) => (
    new Promise((resolve, reject) => {
        const contribuyentes = userInfo.contribuyentes
        let contribuyenteSelected

        if (contribuyentes && contribuyentes.length === 1)
            contribuyenteSelected = contribuyentes[0]
        else if (contribuyentes && contribuyenteId && !isNaN(contribuyenteId))
            contribuyenteSelected = contribuyentes[
                contribuyentes.findIndex(((contribuyente) => contribuyenteId === contribuyente.id))
            ]

        if (!contribuyenteSelected)
            resolve()
        else {
            dispatch(setContribuyente(contribuyenteSelected))
            getPermisos({ contribuyente: contribuyenteSelected.id }).then(({ permisos }) => {
                dispatch(setPermisos(permisos))
                ojearNotificaciones().then(({ notificaciones, sinLeer }) => {
                    dispatch(setNotifications(notificaciones, sinLeer))
                    resolve()
                }).catch(error =>
                    reject(error)
                )
            }).catch((error) =>
                reject(error)
            )
        }
    })
)

export const doLogout = () => (dispatch) => {
    dispatch(setAuthenticated(false, true))
    dispatch(setAuthUser())
    dispatch(setNotifications())
    dispatch(setPermisos())
    dispatch(setContribuyente())
    try {
        clearLocalStorage()
    } catch (error) {
        // Error reading storage
    }
} 
import {
    SET_CONTRIBUYENTE,
    SET_ERROR_UI,
    SET_LOADING_UI,
    SET_NOTIFICATIONS,
    SET_PERMISOS,
} from './actions'

export const reducer = (state = {
    contribuyente: null,
    errorUI: null,
    loadingUI: false,
    notifications: [],
    permisos: null,
    unread: 0,
}, action = {}) => {
    switch (action.type) {
        case SET_ERROR_UI:
            return { ...state, errorUI: action.error, loadingUI: false, }
        case SET_LOADING_UI:
            return { ...state, errorUI: null, loadingUI: action.loading }
        case SET_PERMISOS:
            return { ...state, permisos: action.permisos }
        case SET_CONTRIBUYENTE:
            return { ...state, contribuyente: action.contribuyente }
        case SET_NOTIFICATIONS:
            return { ...state, notifications: action.notifications, unread: action.unread }
        default:
            return state
    }
}
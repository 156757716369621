import axios from 'axios'
import {
    SET_CONTRIBUYENTE,
} from '../reducers/actions'

export const setContribuyente = (contribuyente = null) => ({
    type: SET_CONTRIBUYENTE,
    contribuyente,
})

export const getContribuyenteStorage = () => localStorage.getItem('contribuyente')

export const setContribuyenteStorage = (contribuyenteId) => localStorage.setItem('contribuyente', contribuyenteId)

export const info = (data) =>
    axios.post('/api/empresa/info', data)

export const update = (data) =>
    axios.post('/api/empresa/update', data)

export const getEmisionContribuyente = (data) =>
    axios.post('/api/empresa/emision', data)

export const firma = (data) =>
    axios.post('/api/empresa/firma', data)

export const updateLogo = (data) =>
    axios.post('/api/empresa/logo/update', data)

export const deleteLogo = (data) =>
    axios.post('/api/empresa/logo/delete', data)

export const getInfoPreRegistro = (data) =>
    axios.post('/api/empresa/signup/info', data)

export const terminarRegistro = (data) =>
    axios.post('/api/empresa/signup', data)

export const suscripcion = (data) =>
    axios.post('/api/empresa/suscripcion', data)

export const consumo = (data) =>
    axios.post('/api/empresa/consumo', data)
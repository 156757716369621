import axios from 'axios'

const mainInterceptor = () => {
    axios.interceptors.response.use(
        (response) => response.data,
        (error) => Promise.reject({
            message: !error.response ?
                'Verifique su conexión a internet' : (
                    error.response.data &&
                        error.response.data.error ?
                        error.response.data.error :
                        'Ha ocurrido un error. Intente más tarde'
                ),
            ...error
        })
    )
}

export default mainInterceptor